import React from "react";
import { useNavigate } from "react-router";
import { userServices } from "../../services/userServices";
import "./index.scss";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [registerMessage, setRegisterMessage] = React.useState("");
  const form = React.useRef();

  const regName = /^[a-zA-Z]+$/;
  const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleRegisterUser = (e) => {
    e.preventDefault();

    if (!regName.test(firstName)) {
      alert("Invalid First Name");
      return;
    }

    if (!regName.test(lastName)) {
      alert("Invalid Last Name");
      return;
    }

    if (!regEmail.test(email)) {
      alert("Invalid Email");
      return;
    }
    if (phone.length <= 9) {
      alert("Invalid Phone");
      return;
    }

    if (password.length < 6) {
      alert("Password must be at least 8 or more characters");
      return;
    }
    userServices
      .registerUser({
        firstName,
        lastName,
        email,
        phone,
        password,
      })
      .then((res) => {
        // socket.emit("newRegistration", {
        //   lastName,
        //   email,
        // });
        if (res.hasOwnProperty("message")) {
          setRegisterMessage(res.message);
        } else {
          navigate("/login");
        }
      });
  };
  return (
    <div>
      <div className="sign-up-container">
        <div className="sign-up-panel">
          <h2 className="sign-up-h2-header">SIGN UP</h2>

          <form
            action=""
            method="post"
            ref={form}
            onSubmit={(e) => handleRegisterUser(e)}
            className="sign-up-form"
          >
            <div
              className="sign-up-panel-group"
              style={{ marginTop: "2.2rem" }}
            >
              <input
                className="sign-up-input"
                type="text"
                name="user_firstname"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <span className="sign-up-border"></span>
              <label>First Name</label>
            </div>

            <div className="sign-up-panel-group">
              <input
                className="sign-up-input"
                type="text"
                name="user_lastname"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <span className="sign-up-border"></span>
              <label>Last Name</label>
            </div>

            <div className="sign-up-panel-group">
              <input
                className="sign-up-input"
                type="text"
                pattern="[0-9]*"
                onChange={(e) =>
                  setPhone((v) =>
                    e.target.validity.valid ? e.target.value : v
                  )
                }
                required
              />
              <span className="sign-up-border"></span>
              <label>Phone</label>
            </div>

            <div className="sign-up-panel-group">
              <input
                className="sign-up-input"
                type="email"
                name="user_email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <span className="sign-up-border"></span>
              <label>Email</label>
            </div>

            <div className="sign-up-panel-group">
              <input
                className="sign-up-input"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="sign-up-border"></span>
              <label>Password</label>
            </div>

            <button type="submit">Complete Registration</button>
          </form>

          <div className="sign-up-panel-footer">
            <p>
              Already have an account?{" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                Login now
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
