import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";

function SecurityPage() {
  return (
    <Box className="security-policy-page-container">
      <Box>
        <h1 className="security-policy-page-header">Security</h1>
      </Box>
      <Box className="security-policy-page-textBox">
        <span className="security-policy-page-span">
          Great North Markets views its customers' information security as being
          of the utmost importance, and therefore invests considerable resources
          for protecting customers' information securely while enabling
          interaction with the site and utilizing the most advanced and powerful
          security procedures and encryption systems available worldwide.
          Recommendations and guidelines for the use of this site Encrypted
          Communications You should verify that either a closed lock is shown in
          the upper or lower section of the window, or a complete key (depending
          upon which browser version is installed on your computer). This symbol
          will appear when you execute transactions in your account.
          Double-clicking on the lock or key symbol will display the digital
          certificate issued to this site. Verify that the issuing organization
          is "GoDaddy" and that the certificate was issued to Great North
          Markets. Identification You access the system by entering a username
          and password. This data is personal and must not be divulged to
          others. While logging into the system using your personal identifying
          details, it is recommended that no unauthorized persons are in your
          vicinity who can view this information. Your personal password must
          never be divulged to others, not even to service personnel. If anyone
          requests your password, do not disclose it and immediately notify
          customer service. Never save your username or password on or near a
          computer. Passwords Selection of password - You should choose a
          password that cannot be easily deciphered. Furthermore, your password
          should consist of random characters and digits. Password replacement -
          You should change your password every three months. In the event that
          your password may have been revealed to others - immediately change
          the password. End of Activity in the System Exit the system by a press
          on "Logout". Information Security Resources in the Great North Markets
          System Access to the site system is secured and controlled by a
          firewall that enables access only to those services that are required
          by the system. Information transmitted between the site and your
          personal computer is encrypted using a 128 bit ssl encryption
          certificate that has been provided for the site by "GoDaddy". Great
          North Markets supervises and controls the systems' communications, and
          records all activities taking place in the system. Routine information
          security checks are carried out by our information security experts.
        </span>
      </Box>
    </Box>
  );
}

export default SecurityPage;
