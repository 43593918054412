import { Box } from "@material-ui/core";
import "./index.scss";
import Reveal, { Roll, Slide } from "react-awesome-reveal";

const PlatformPage = () => {
  // const mobileWidth = window.innerWidth;
  return (
    <>
      <div
        style={{
          marginTop: window.location.href.includes("platform") ? "3rem" : "0",
        }}
        className="platform-page-video-container"
      >
        <video
          style={{
            display: "inline-block",
            verticalAlign: "baseline",
          }}
          preload="none"
          autoPlay
          muted
          loop
          data-video-init="true"
          width="auto"
          height="1200"
          src="moving-chart.mp4"
        >
          <source src="moving-chart.mp4" type="video/mp4"></source>
        </video>
      </div>
      {/* <h1
        style={{
          display: !window.location.href.includes("platform")
            ? "none"
            : "block",
        }}
        className="platform-page-header"
      >
        Platform
      </h1> */}
      <Box className="platform-page-container">
        <Reveal duration={2000}>
          <Box className="platform-page-textBox">
            <h3 className="platform-page-h3">
              Trade the World's Top Assets with <br></br>our High Tech Trading
              Platforms
            </h3>
            <Box className="platform-page-span">
              <span>
                Great North Markets is dedicated to serve its worldwide clients
                when trading online CFDs (Contracts for differences) and many
                other financial instruments. <br></br>
                <br></br>To simplify and make the trading process safe, Great
                North Markets offers an innovative trading platform that allows
                trading with all kind of financial instruments everything in one
                place-from commodities to cryptos.
              </span>
            </Box>
          </Box>
        </Reveal>
        <Slide direction="right" duration={2000}>
          <Box className="platform-page-imagebox2">
            <img src="screens.webp" width="800px" alt="asset-png" />
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default PlatformPage;
