import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import SubNavbar from "../../components/SubNavbar";

import "./index.scss";
import AccountTypesComparison from "../accountTypesComparison";
function AccountTypesPage() {
  const mobileWidth = window.innerWidth;
  const navigate = useNavigate();
  const arrOfAccountTypes = [
    {
      type: "STANDARD",
      price: "$250.00",
    },
    {
      type: "ADVANCED",
      price: "$25,000.00",
    },
    {
      type: "EXPERT",
      price: "$50,000.00",
    },
    {
      type: "VIP",
      price: "$100,000.00",
    },
    {
      type: "ISLAMIC",
      price: "$250,000.00",
    },
  ];
  return (
    <div className="account-types-container-parent-root">
      <SubNavbar></SubNavbar>
      <div className="account-types-container-parent">
        <div className="account-types-container">
          <div className="account-types-header">
            <h1> ACCOUNT TYPES</h1>
            {/* <p className="account-types-p">
              Great North Markets offers a variety of account levels for its
              customers in view of their level of trading experience.
            </p> */}
          </div>
          {/* <div className="account-types-cards">
            {arrOfAccountTypes.map((card) => (
              <Card className="account-types-card-container">
                <CardActionArea className="account-types-card-action-area">
                  <CardContent className="account-types-card-content">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      borderColor={"#0D6372"}
                      className="account-types-card-typography"
                    >
                      {card.type}
                    </Typography>
                    <Typography variant="body2" color="white">
                      {card.price}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className="account-types-card-actions">
                  <Button
                    size="large"
                    color="inherit"
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={() => navigate("/deposit")}
                  >
                    DEPOSIT
                  </Button>
                </CardActions>
              </Card>
            ))} */}
          {/* </div> */}
          <div style={{ marginTop: "1rem" }}>
            <AccountTypesComparison />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountTypesPage;
